<template>
  <div>
    <div>


      <b-card>

        <!-- Search setion -->
        <b-row>
          <b-col cols="12" md="4">
            <h5>Status</h5>
            <v-select v-model="selectedStatus" multiple label="title" :options="options" class="select-size-sm" />
          </b-col>
          <!-- Search -->
          <b-col cols="12" md="3">
            <b-form-group>
              <h5>Range appointment date</h5>
              <flat-pickr v-model="rangeDate" class="form-control" :config="{ mode: 'range' }" size="sm"
                style="height: 30px" />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="3">
            <b-form-group>
              <h5>Search</h5>
              <b-form-input v-model="searchingText" @keyup="masterSearch($event)" size="sm" type="text" />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="1" class="mb-1 ml-1" style="margin-top: 1.7rem !important">
            <b-button class="btn btn-primary float-right" @click="getClientesByFilter('')" size="sm"
              style="background: #7367f0 !important">
              Filter
            </b-button>
          </b-col>
        </b-row>
        <!--End Search setion -->


        <!-- Update status setion -->
        <b-row>
          <b-col cols="12" md="2">
            <b-form-group>
              <h5>Confirmation #</h5>
              <b-form-input size="sm" v-model="ticketToUpdateNumber" disabled type="text" />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="3">
            <h5>Status</h5>
            <v-select v-model="ticketToUpdateStatus" label="title" :options="options" class="select-size-sm" />
          </b-col>
          <b-col cols="12" md="2" class="mb-1 ml-1" style="margin-top: 1.7rem !important">
            <b-button @click="
              updateStatusBooking(
                ticketToUpdateNumber,
                ticketToUpdateStatus,
                currentItem
              )
              " style="
              background-color: rgb(115, 103, 240) !important;
              color: rgb(255, 255, 255) !important;
            " size="sm">
              Update status
            </b-button>
          </b-col>

          <b-col cols="12" md="3">
            <b-form-group>
              <h5>Driver assigned</h5>
              <b-form-input v-model="selectDriverName" size="sm" type="text" v-b-modal.modal-search-driver />

            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mt-1">
          <b-col>
            <b-button class="btn" style="
              background-color: rgb(115, 103, 240) !important;
              color: white !important; margin-top: 10px;
            " size="sm" v-b-modal.modal-options-pdf @click="actionPdf = CREATEPDFDRIVER">
              PDF ticket driver
            </b-button>
            <b-button class="btn" style="
              background-color: rgb(115, 103, 240) !important;
              color: white !important;
              margin-left: 10px;margin-top: 10px;
            " size="sm" v-b-modal.modal-options-pdf @click="actionPdf = MAILPDFDRIVER">
              Mail the driver's ticket
            </b-button>
            <b-button class="btn" style="
              background-color: rgb(115, 103, 240) !important;
              color: white !important;
              margin-left: 20px;margin-top: 10px;" size="sm" v-b-modal.modal-options-pdf
              @click="actionPdf = CREATEPDFCLIENT">
              PDF Details Booking
            </b-button>
            <b-button class="btn" style="
              background-color: rgb(115, 103, 240) !important;
              color: white !important;margin-left: 10px;margin-top: 10px;" size="sm" v-b-modal.modal-options-pdf
              @click="actionPdf = MAILPDFCLIENT">
              Mail booking details to the customer
            </b-button>
            <b-button class="btn" style="
              background-color: rgb(115, 103, 240) !important;
              color: white !important;margin-left: 10px;margin-top: 10px;" size="sm" v-b-modal.modal-options-pdf
              @click="actionPdf = MAILPDFCOMPANY">
              Mail booking details to company
            </b-button>
          </b-col>
        </b-row>
        <!--End Update status setion -->
      </b-card>

      <b-row>
        <b-col>
          <b-button class="btn btn-success mr-2 mt-1" @click="exportBooking()" size="sm" style="float: right;">
            Export data
          </b-button>
        </b-col>
      </b-row>


      <br />
      <div style="overflow-x: scroll; min-height: 500px" v-if="totalItems > 0">
        <table small caption-top responsive style="font-size: small" id="table" class="table b-table">
          <thead>
            <tr>
              <th>Actions</th>
              <th aria-sort="none" @click="getItemsSort('booking_id')"><i class="fa fa-fw fa-sort"></i>Confirmation
                number
              </th>
              <th @click="getItemsSort('assigned_employee')">Collab Users</th>
              <th @click="getItemsSort('selfpay_name')">Customer</th>
              <th @click="getItemsSort('selfpay_phone_number')">Customer Phone</th>
              <th @click="getItemsSort('statuscodes_status')">Status</th>
              <th @click="getItemsSort('service_type')">Service Type</th>
              <th @click="getItemsSort('payment_type')">Booking Category</th>
              <th @click="getItemsSort('company_legal_name')">Company</th>
              <th @click="getItemsSort('pickup_time')">PickUp time</th>
              <th @click="getItemsSort('appoinment_datetime')">Appointment Datetime</th>
              <th aria-sort="none" class="headerth" @click="getItemsSort('garage_time')"> Central zone time</th>
              <th @click="getItemsSort('driver_name')">Driver</th>
              <th @click="getItemsSort('driver_vehicle_type')">Vehicle Type</th>
              <th @click="getItemsSort('driver_phone_number')">Driver phone</th>
              <th @click="getItemsSort('from')" style="min-width: 100px">Pickup Address</th>
              <th @click="getItemsSort('to')" style="min-width: 100px">Drop Off Address</th>
              <th>Wait Time</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in listItems" :key="item.booking_id" @click="getTicketData(item)">
              <!-- Start Actions  -->
              <td>
                <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL" transition="scale-transition"
                  :offset-y="true">
                  <template #button-content>
                    <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                  </template>
                  <template v-slot:activator="{ on, attrs }">
                    <b-btn color="primary" v-bind="attrs" v-on="on" icon ripple>
                    </b-btn>
                  </template>
                  <b-list-group style="padding: 0px; margin-bottom: 0px" dense rounded>
                    <b-list-group-item style="padding: 0" class="urlPagina" :ripple="false">
                      <b-list-group-item class="font-weight-bold" style="border: none; padding: 5px; color: #7367f0"
                        @click="openUpdateBooking(item.booking_id)">
                        <feather-icon icon="CheckIcon" />
                        Edit
                      </b-list-group-item>
                    </b-list-group-item>
                  </b-list-group>
                  <b-list-group style="padding: 2px; margin-bottom: 2px" dense rounded>
                    <router-link class="urlPagina" :to="{ name: 'details-booking', query: { id: item.booking_id } }">
                      <b-list-group-item style="padding: 0" class="urlPagina" :ripple="false">
                        <b-list-group-item class="font-weight-bold" style="border: none; padding: 5px">
                          <feather-icon icon="FileTextIcon" />
                          Details
                        </b-list-group-item>
                      </b-list-group-item>
                    </router-link>
                  </b-list-group>
                  <b-list-group style="padding: 2px; margin-bottom: 2px" dense rounded>
                    <router-link class="urlPagina"
                      :to="{ name: 'details-assign-driver', params: { id: item.booking_id } }">
                      <b-list-group-item style="padding: 0" class="urlPagina" :ripple="false">
                        <b-list-group-item class="font-weight-bold" style="border: none; padding: 5px">
                          <feather-icon icon="CheckIcon" />
                          Assign driver
                        </b-list-group-item>
                      </b-list-group-item>
                    </router-link>
                  </b-list-group>
                  <b-list-group style="padding: 2px; margin-bottom: 2px" dense rounded>
                    <b-list-group-item style="padding: 0" class="urlPagina" :ripple="false">
                      <b-list-group-item class="font-weight-bold" style="border: none; padding: 5px; color: #7367f0"
                        v-b-modal.bv-modal-example @click="setValuesForm(item)">
                        <feather-icon icon="CheckIcon" />
                        Send SMS
                      </b-list-group-item>
                    </b-list-group-item>
                  </b-list-group>
                  <b-list-group style="padding: 2px; margin-bottom: 2px" dense rounded>
                    <b-list-group-item style="padding: 0" class="urlPagina" :ripple="false">
                      <b-list-group-item class="font-weight-bold" style="border: none; padding: 5px; color: #7367f0"
                        v-b-modal.bv-modal-add-note @click="setValuesForm(item)">
                        <feather-icon icon="CheckIcon" />
                        Add note
                      </b-list-group-item>
                    </b-list-group-item>
                  </b-list-group>
                  <b-list-group style="padding: 2px; margin-bottom: 2px" dense rounded>
                    <router-link name="timeline" class="urlPagina" :to="{
                      name: 'details-booking-notes',
                      query: { id: item.booking_id },
                    }">
                      <b-list-group-item style="padding: 0" class="urlPagina" :ripple="false">
                        <b-list-group-item class="font-weight-bold" style="border: none; padding: 5px">
                          <feather-icon icon="FileTextIcon" />
                          List notes
                        </b-list-group-item>
                      </b-list-group-item>
                    </router-link>
                  </b-list-group>
                  <!-- Timer -->
                  <b-list-group style="padding: 2px; margin-bottom: 2px" dense rounded>
                    <b-list-group-item style="padding: 0" :id="'start' + item.booking_id"
                      :class="'urlPagina start' + item.booking_id" :ripple="false">
                      <b-list-group-item class="font-weight-bold btn-success"
                        style="border: none; padding: 5px; color: #7367f0" @click="startTimer(item, true)">
                        <feather-icon icon="CheckIcon" />
                        Start Timer
                      </b-list-group-item>
                    </b-list-group-item>
                  </b-list-group>
                  <b-list-group style="padding: 2px; margin-bottom: 2px" dense rounded>
                    <b-list-group-item style="padding: 0; display: none" :id="'stop' + item.booking_id"
                      :class="'urlPagina stop' + item.booking_id" :ripple="false">
                      <b-list-group-item class="font-weight-bold btn-danger"
                        style="border: none; padding: 5px; color: #7367f0" @click="stopTimer(item)">
                        <feather-icon icon="CheckIcon" />
                        Stop Timer
                      </b-list-group-item>
                    </b-list-group-item>
                  </b-list-group>
                  <!--End Timer -->
                  <b-list-group style="padding: 2px; margin-bottom: 2px" dense rounded>
                    <b-list-group-item style="padding: 0" class="urlPagina" :ripple="false"
                      v-if="$hasPermission('delete-records')">
                      <b-list-group-item class="font-weight-bold" style="border: none; padding: 5px"
                        @click="deleteReservation(item.booking_id)">
                        <feather-icon icon="TrashIcon" />
                        Delete
                      </b-list-group-item>
                    </b-list-group-item>
                  </b-list-group>
                  <b-list-group style="padding: 2px; margin-bottom: 2px" dense rounded>
                    <b-list-group-item style="padding: 0" class="urlPagina" :ripple="false">
                      <b-list-group-item class="font-weight-bold" style="border: none; padding: 5px"
                        v-b-modal.modal-map-driver @click="setCurrentBooking(item)">
                        <feather-icon icon="TrashIcon" />
                        Map driver
                      </b-list-group-item>
                    </b-list-group-item>
                  </b-list-group>
                </b-dropdown>
              </td>
              <!-- End Actions  -->
              <td>{{ item.booking_id }}</td>
              <td>{{ getCollabUser(item.assigned_employee) }}</td>
              <td>{{ item.selfpay_name == null ? "" : item.selfpay_name }} {{
                item.selfpay_lastname == null ? "" : item.selfpay_lastname }}</td>
              <td>{{ item.selfpay_phone_number }}</td>
              <!-- Start Status  -->
              <td v-if="item.statuscodes_code == 0" style="background: #e89e15; color: white">
                {{ item.statuscodes_status }}
              </td>
              <td v-if="item.statuscodes_code == 1" style="color: white; background: #5d1e76">
                {{ item.statuscodes_status }}
              </td>
              <td v-if="item.statuscodes_code == 2" style="background: #a067a0; color: white">
                {{ item.statuscodes_status }}
              </td>
              <td v-if="item.statuscodes_code == 3" style="background: #329432; color: white">
                {{ item.statuscodes_status }}
              </td>
              <td v-if="item.statuscodes_code == 4" style="background: #f9cfcf; color: crimson">
                {{ item.statuscodes_status }}
              </td>
              <td v-if="item.statuscodes_code == 5" style="background: #cdcdcd">
                {{ item.statuscodes_status }}
              </td>
              <td v-if="item.statuscodes_code == 6" style="background: #b0b02f; color: white">
                {{ item.statuscodes_status }}
              </td>
              <td v-if="item.statuscodes_code == 7" style="background: #4b4b4b; color: white">
                {{ item.statuscodes_status }}
              </td>
              <td v-if="item.statuscodes_code == 8" style="background: royalblue; color: white">
                {{ item.statuscodes_status }}
              </td>
              <!-- <td v-if="item.status == 8">{{ item.statuscodes_status }}</td> -->
              <td v-if="item.statuscodes_code == 9" style="background: #6161ff; color: white">
                {{ item.statuscodes_status }}
              </td>
              <td v-if="item.statuscodes_code == 10" style="background: rgb(198 239 38); color: black">
                {{ item.statuscodes_status }}
              </td>
              <td v-if="item.statuscodes_code == 11" style="background: rgb(233 78 62); color: #c9c6c6">
                {{ item.statuscodes_status }}
              </td>
              <td v-if="item.statuscodes_code == 12" style="background: rgb(247, 91, 187); color: #fcfcfc">
                {{ item.statuscodes_status }}
              </td>
              <td v-if="item.statuscodes_code == 13" style="background: rgb(93, 10, 72); color: #fcfcfc">
                {{ item.statuscodes_status }}
              </td>
              <td v-if="item.statuscodes_code == 14" style="background: rgb(180 244 224); color: #fcfcfc">
                {{ item.statuscodes_status }}
              </td>
              <td v-if="item.statuscodes_code == 15" style="background: rgb(31, 24, 136); color: #fcfcfc">
                {{ item.statuscodes_status }}
              </td>
              <td v-if="item.statuscodes_code == 16" style="background: rgb(167 223 238);white">
                {{ item.statuscodes_status }}
              </td>
              <!-- End Status  -->
              <td>{{ getServiceType(item.service_type) }}</td>
              <td>{{ getTypeClient(item) }}</td>
              <td>{{ getCompanyName(item) }}</td>
              <td>{{ convertTime2(item.booking_pickup_time) }}</td>
              <td>
                {{ convertDate(item.appoinment_datetime) }}
                {{ convertTime(item.appoinment_datetime) }}
              </td>
              <td>
                {{ convertDate(item.garage_time) }}
                {{ convertTime(item.garage_time) }}
              </td>

              <td>{{ item.driver_name == null ? "" : item.driver_name }} {{ item.driver_lastname == null ? "" :
                item.driver_lastname
                }}</td>
              <td>{{ getDriverVehicle(item.driver_vehicle_type) }}</td>
              <td>{{ item.driver_phone_number }}</td>

              <td>{{ getAddress(item.from).from }}</td>
              <td>{{ getAddress(item.to).to }}</td>
              <td>
                <span class="'span' + item.booking_id" :id="'span' + item.booking_id"><span
                    :id="'display' + item.booking_id">{{
                      item.passenger_waiting_time_for_driver
                    }}</span></span>
              </td>
              <!-- Start Actions  -->
              <td>
                <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL" transition="scale-transition"
                  :offset-y="true">
                  <template #button-content>
                    <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                  </template>
                  <template v-slot:activator="{ on, attrs }">
                    <b-btn color="primary" v-bind="attrs" v-on="on" icon ripple>
                    </b-btn>
                  </template>
                  <b-list-group style="padding: 0px; margin-bottom: 0px" dense rounded>
                    <b-list-group-item style="padding: 0" class="urlPagina" :ripple="false">
                      <b-list-group-item class="font-weight-bold" style="border: none; padding: 0px; color: #7367f0"
                        @click="openUpdateBooking(item.booking_id)">
                        <feather-icon icon="CheckIcon" />
                        Edit
                      </b-list-group-item>
                    </b-list-group-item>
                  </b-list-group>
                  <b-list-group style="padding: 0px; margin-bottom: 0px" dense rounded>
                    <router-link class="urlPagina" :to="{ name: 'details-booking', query: { id: item.booking_id } }">
                      <b-list-group-item style="padding: 0" class="urlPagina" :ripple="false">
                        <b-list-group-item class="font-weight-bold" style="border: none; padding: 0px">
                          <feather-icon icon="FileTextIcon" />
                          Details
                        </b-list-group-item>
                      </b-list-group-item>
                    </router-link>
                  </b-list-group>
                  <b-list-group style="padding: 2px; margin-bottom: 2px" dense rounded>
                    <router-link class="urlPagina"
                      :to="{ name: 'details-assign-driver', params: { id: item.booking_id } }">
                      <b-list-group-item style="padding: 0" class="urlPagina" :ripple="false">
                        <b-list-group-item class="font-weight-bold" style="border: none; padding: 5px">
                          <feather-icon icon="CheckIcon" />
                          Assign driver
                        </b-list-group-item>
                      </b-list-group-item>
                    </router-link>
                  </b-list-group>
                  <b-list-group style="padding: 0px; margin-bottom: 0px" dense rounded>
                    <b-list-group-item style="padding: 0px" class="urlPagina" :ripple="false">
                      <b-list-group-item class="font-weight-bold" style="border: none; padding: 5px; color: #7367f0"
                        v-b-modal.bv-modal-example @click="setValuesForm(item)">
                        <feather-icon icon="CheckIcon" />
                        Send Sms
                      </b-list-group-item>
                    </b-list-group-item>
                  </b-list-group>
                  <b-list-group style="padding: 2px; margin-bottom: 2px" dense rounded>
                    <b-list-group-item style="padding: 0" class="urlPagina" :ripple="false">
                      <b-list-group-item class="font-weight-bold" style="border: none; padding: 5px; color: #7367f0"
                        v-b-modal.bv-modal-add-note @click="setValuesForm(item)">
                        <feather-icon icon="CheckIcon" />
                        Add note
                      </b-list-group-item>
                    </b-list-group-item>
                  </b-list-group>
                  <b-list-group style="padding: 0px; margin-bottom: 0px" dense rounded>
                    <router-link name="timeline" class="urlPagina" :to="{
                      name: 'details-booking-notes',
                      query: { id: item.booking_id },
                    }">
                      <b-list-group-item style="padding: 0" class="urlPagina" :ripple="false">
                        <b-list-group-item class="font-weight-bold" style="border: none; padding: 0px">
                          <feather-icon icon="FileTextIcon" />
                          List notes
                        </b-list-group-item>
                      </b-list-group-item>
                    </router-link>
                  </b-list-group>
                  <!-- Timer -->
                  <b-list-group v-if="item.status != 3" style="padding: 0px; margin-bottom: 0px" dense rounded>
                    <b-list-group-item style="padding: 0" :id="'start' + item.booking_id"
                      :class="'urlPagina start' + item.booking_id" :ripple="false">
                      <b-list-group-item class="font-weight-bold btn-success"
                        style="border: none; padding: 5px; color: #7367f0" @click="startTimer(item, true)">
                        <feather-icon icon="CheckIcon" />
                        Start Timer
                      </b-list-group-item>
                    </b-list-group-item>
                  </b-list-group>
                  <b-list-group v-if="item.status != 3" style="padding: 0px; margin-bottom: 0px" dense rounded>
                    <b-list-group-item style="padding: 0; display: none" :id="'stop' + item.booking_id"
                      :class="'urlPagina stop' + item.booking_id" :ripple="false">
                      <b-list-group-item class="font-weight-bold btn-danger"
                        style="border: none; padding: 5px; color: #7367f0" @click="stopTimer(item)">
                        <feather-icon icon="CheckIcon" />
                        Stop Timer
                      </b-list-group-item>
                    </b-list-group-item>
                  </b-list-group>
                  <b-list-group style="padding: 2px; margin-bottom: 2px" dense rounded>
                    <b-list-group-item style="padding: 0" class="urlPagina" :ripple="false"
                      v-if="$hasPermission('delete-records')">
                      <b-list-group-item class="font-weight-bold" style="border: none; padding: 5px"
                        @click="deleteReservation(item.booking_id)">
                        <feather-icon icon="TrashIcon" />
                        Delete
                      </b-list-group-item>
                    </b-list-group-item>
                  </b-list-group>
                  <b-list-group style="padding: 2px; margin-bottom: 2px" dense rounded>
                    <b-list-group-item style="padding: 0" class="urlPagina" :ripple="false">
                      <b-list-group-item class="font-weight-bold" style="border: none; padding: 5px"
                        v-b-modal.modal-map-driver @click="setCurrentBooking(item)">
                        <feather-icon icon="TrashIcon" />
                        Map driver
                      </b-list-group-item>
                    </b-list-group-item>
                  </b-list-group>
                  <!--End Timer -->
                </b-dropdown>
              </td>
              <!-- End Actions  -->
            </tr>
          </tbody>
          <thead>
            <tr>
              <th>Actions</th>
              <th aria-sort="none" @click="getItemsSort('booking_id')"><i class="fa fa-fw fa-sort"></i>Confirmation
                number
              </th>
              <th @click="getItemsSort('assigned_employee')">Collab Users</th>
              <th @click="getItemsSort('selfpay_name')">Customer</th>
              <th @click="getItemsSort('selfpay_phone_number')">Customer Phone</th>
              <th @click="getItemsSort('statuscodes_status')">Status</th>
              <th @click="getItemsSort('service_type')">Service Type</th>
              <th @click="getItemsSort('payment_type')">Booking Category</th>
              <th @click="getItemsSort('company_legal_name')">Company</th>
              <th @click="getItemsSort('pickup_time')">PickUp time</th>
              <th @click="getItemsSort('appoinment_datetime')">Appointment Datetime</th>
              <th aria-sort="none" class="headerth" @click="getItemsSort('garage_time')"> Central zone time</th>
              <th @click="getItemsSort('driver_name')">Driver</th>
              <th @click="getItemsSort('driver_vehicle_type')">Vehicle Type</th>
              <th @click="getItemsSort('driver_phone_number')">Driver phone</th>
              <th @click="getItemsSort('from')" style="min-width: 100px">Pickup Address</th>
              <th @click="getItemsSort('to')" style="min-width: 100px">Drop Off Address</th>
              <th>Wait Time</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td :class="{ show_space: isShowSpace }" colspan="17" variant="secondary" class="text-left">
                Total Rows: <b>{{ totalItems }}</b>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="pagination_block" v-if="totalItems > 0">
        <nav aria-label="Page navigation example">
          <ul class="pagination justify-content-center">
            <li class="page-item" :class="{ disabled: pagination.prev_page_url == null }">
              <button type="button" class="page-link" @click="getItems(pagination.first_page_url)">
                &laquo;
              </button>
            </li>
            <li class="page-item" :class="{ disabled: pagination.prev_page_url == null }">
              <button type="button" class="page-link" @click="getItems(pagination.prev_page_url)">
                Previous
              </button>
            </li>
            <li class="page-item" v-if="current_page - 2 > 0">
              <button type="button" class="page-link" @click="
                getItems(pagination.path + '?page=' + (current_page - 2))
                ">
                {{ current_page - 2 }}
              </button>
            </li>
            <li class="page-item" v-if="pagination.prev_page_url">
              <button type="button" class="page-link" @click="getItems(pagination.prev_page_url)">
                {{ current_page - 1 }}
              </button>
            </li>
            <li class="page-item">
              <button type="button" class="page-link" :class="{ current: pagination.current_page == current_page }">
                {{ current_page }}
              </button>
            </li>
            <li class="page-item" v-if="pagination.next_page_url">
              <button type="button" class="page-link" @click="getItems(pagination.next_page_url)">
                {{ current_page + 1 }}
              </button>
            </li>
            <li class="page-item" v-if="current_page + 2 <= last_page">
              <button type="button" class="page-link" @click="
                getItems(pagination.path + '?page=' + (current_page + 2))
                ">
                {{ current_page + 2 }}
              </button>
            </li>
            <li class="page-item" :class="{ disabled: pagination.next_page_url == null }">
              <button type="button" class="page-link" @click="getItems(pagination.next_page_url)">
                Next
              </button>
            </li>
            <li class="page-item" :class="{ disabled: pagination.next_page_url == null }">
              <button type="button" class="page-link" @click="getItems(pagination.last_page_url)">
                &raquo;
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <b-modal id="modal-center" centered hide-footer>
      <div class="d-block text-center">
        <h3>Write a reason to cancel the trip</h3>
        <b-col>
          <b-form-group>
            <b-form-input v-model="noteText" @mouseover="changeColor()"
              v-bind:class="{ 'border border-danger': isNoteText == false }" />
          </b-form-group>
        </b-col>
      </div>
      <b-row class="mt-3">
        <b-col>
          <b-button block @click="cancelBooking(currentId)">Save</b-button>
        </b-col>
        <b-col>
          <b-button block @click="$bvModal.hide('modal-center')">Close</b-button>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal id="bv-modal-example" size="lg" centered hide-footer>
      <div class="d-block text-center">
        <h3>Write a SMS</h3>
        <b-col>
          <b-form-group>
            <b-form-textarea v-model="messageText" @mouseover="changeColorMessage()"
              v-bind:class="{ 'border border-danger': isMessageText == false }" rows="6" />
          </b-form-group>
        </b-col>
        <b-col>
          <div>
            <b-form-group>
              <b-form-checkbox v-model="isDefaultMessage" @change="setDefaulText" unchecked-value=""
                style="float: right;">
                Default sms message
              </b-form-checkbox>
            </b-form-group>
          </div>
        </b-col>
        <b-col>
          <b-form-group class="mb-1">
            <div style="display: flex; gap: 10px;">
              <b-form-checkbox id="passanger" v-model="messageSendTo" name="passanger" value="passanger"
                unchecked-value="" class="col-4" style="float: right;">
                Send SMS note to Passenger
              </b-form-checkbox>
              <b-form-input v-model="currentAlternativeNumberPassenger" class="col-8"
                placeholder="Alternative Number" />
            </div>
          </b-form-group>
          <b-form-group class="mb-0">
            <div style="display: flex; gap: 10px;">
              <b-form-checkbox class="col-4" id="driver" v-model="messageSendTo" name="driver" value="driver"
                unchecked-value="" style="float: right;">
                Send SMS note to Driver
              </b-form-checkbox>
              <b-form-input class="col-8" v-model="currentAlternativeNumberDriver" placeholder="Alternative Number" />
            </div>
          </b-form-group>
        </b-col>
      </div>
      <b-row class="mt-3">
        <b-col>
          <b-button block @click="saveNote(currentId, 'Sms', 'sms')">Save</b-button>
        </b-col>
        <b-col>
          <b-button block @click="$bvModal.hide('bv-modal-example')">Close</b-button>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal ref="modalSearchDriver" id="modal-search-driver" cancel-only centered size="xl" title="Assign a driver">
      <div>
        <b-row class="mb-2">
          <b-col offset-md="0" md="4">
            Search
            <b-form-input v-model="filterNameDriver" />
          </b-col>
        </b-row>

        <b-table :filter="filterNameDriver" striped hover :items="listDrivers2" :fields="fields2"
          :per-page="perPageTableDriver" :current-page="currentPageTableDriver" small>
          <template #cell(actions)="{ item }">
            <b-button @click="selectDriver(item, $bvModal)" class="btn" style="
              background-color: rgb(115, 103, 240) !important;
              color: white !important;
            " size="sm">
              Assign
            </b-button>
          </template>
        </b-table>

        <b-pagination v-model="currentPageTableDriver" :total-rows="listDrivers2.length" :per-page="perPageTableDriver"
          aria-controls="my-table"></b-pagination>
      </div>


      <template #modal-footer>

      </template>
    </b-modal>

    <b-modal id="bv-modal-add-note" centered hide-footer size="lg">
      <div class="d-block text-center">
        <h3>Write a Note</h3>
        <b-row>
          <b-col>
            <b-form-group>
              <b-form-textarea v-model="messageText" @mouseover="changeColorMessage()"
                v-bind:class="{ 'border border-danger': isMessageText == false }" rows="6" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <div style="display:flex" class="mb-1">
            <b-form-radio class="ml-1" name="radios-type-note" v-model="typeNote" value="Internal note">Internal
              note</b-form-radio>
            <b-form-radio class="ml-1 mr-1" name="radios-type-note" v-model="typeNote" value="Note for driver">Note for
              driver</b-form-radio>
            <b-form-radio class="ml-1 mr-1" name="radios-type-note" v-model="typeNote" value="Note for client">Note for
              client</b-form-radio>
            <b-form-radio class="ml-1 mr-1" name="radios-type-note" v-model="typeNote" value="Note for billing">Note
              for
              billing</b-form-radio>
          </div>
        </b-row>
        <b-row>
          <b-col cols="12" md="9">
            <v-select v-model="selectedDescriptionNote" transition="" label="title" :options="optionsNotes"
              class="select-size-sm" />
          </b-col>
          <b-col cols="12" md="3">
            <b-button size="sm" @click="addDescriptionNote">Add</b-button>
          </b-col>
        </b-row>
      </div>
      <b-row class="mt-3">
        <b-col>
          <b-button block @click="saveNote(currentId, typeNote, 'note')">Save</b-button>
        </b-col>
        <b-col>
          <b-button block @click="$bvModal.hide('bv-modal-example')">Close</b-button>
        </b-col>
      </b-row>
    </b-modal>


    <b-modal id="modal-options-pdf" centered hide-footer>
      <div class="d-block text-center">
        <h3 class="mb-4">Subject of the trip</h3>
        <b-col>
          <div style="display:flex;text-align: left;">
            <b-form-radio v-model="subjectPdf" name="options-pdf" :value="NEWBOOKINGCONFIRMATION">New
              confirmation</b-form-radio>
            <b-form-radio class="ml-2" v-model="subjectPdf" name="options-pdf"
              :value="UPDATEDBOOKINGCONFIRMATION">Updated
              Confirmation</b-form-radio>
            <b-form-radio class="ml-2" v-model="subjectPdf" name="options-pdf"
              :value="CANCELLEDBOOKINGCONFIRMATION">Cancelled Confirmation</b-form-radio>
          </div>
        </b-col>
      </div>
      <b-row class="mt-4">
        <b-col>
          <b-button @click="processActionPdf(actionPdf, subjectPdf)" block>Ok</b-button>
        </b-col>
        <b-col>
          <b-button block @click="$bvModal.hide('modal-options-pdf')">Close</b-button>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal id="modal-error-driver-price" centered size="sm" hide-footer>
      <div style="text-align: center;">
        <span class="mb-2" style="color: red;">Driver price must have a value</span>

        <br>
        <router-link target="_blank" class="urlPagina"
          :to="{ name: 'details-reservation', params: { id: ticketToUpdateNumber } }">
          Change driver price here
        </router-link>
      </div>

      <b-col class="col-6 mt-2">
      </b-col>
      <b-col style="display: flex;flex-direction: row;align-content: flex-start;justify-content: space-around;">
        <b-button class="col-6" block @click="$bvModal.hide('modal-error-driver-price')">Close</b-button>
      </b-col>


    </b-modal>

    <template v-if="currentBooking != null">
      <b-modal id="modal-map-driver" centered size="xl" hide-footer>
        <MapDrivers :bookingId="currentBooking.booking_id" 
           ></MapDrivers>
      </b-modal>
    </template>

  </div>
</template>

<script>
import moment from "moment";
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormTextarea,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BListGroup,
  BListGroupItem,
  BFormSelect,
  BTableSimple,
  BThead,
  BTr,
  BTd,
  BTh,
  BTbody,
  BTfoot,
  BFormGroup,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormRadio,
  BImg,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { getMessageError } from "@core/utils/utils";
import { convertToMMDDYYYY, convertToHHMM, convertToHHMM2, addTimes, timeToSecs, secsToTime } from "@core/utils/dates";
import UserListAddNew from "@core/components/infoClients/UserListAddNew";
import { selectedRow } from "@core/utils/cssStyle";
import { getMenuNotes } from "@core/utils/menus";
import SocketioService from "@core/utils/SocketioService";
import MapDrivers from "@core/components/maps/MapDrivers.vue";
export default {
  name: "ListReservationToCancel",
  components: {
    UserListAddNew,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormTextarea,
    BFormCheckbox,
    BFormCheckboxGroup,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BListGroup,
    BListGroupItem,
    BFormSelect,
    BTableSimple,
    BThead,
    BTr,
    BTd,
    BTh,
    BTbody,
    BTfoot,
    BFormGroup,
    flatPickr,
    vSelect,
    BFormInput,
    moment,
    BFormRadio,
    BImg,
    MapDrivers
  },
  data() {
    return {
      BFormInput: "",
      messageSendTo: [],
      isDefaultMessage: null,
      textDefaultMessage: "",
      isNoteText: true,
      noteText: "",
      isMessageText: true,
      messageText: "",
      currentId: "",
      status: "",
      currentAlternativeNumberDriver: "",
      currentAlternativeNumberPassenger: "",
      totalSeconds: [],
      timerInterval: [],
      hour: 0,
      sec: 0,
      min: 0,
      dispHour: 0,
      dispMin: 0,
      dispSec: 0,
      listItems: [],
      perPage: 10,
      currentPage: 1,
      totalItems: 0,
      pageOptions: [3, 5, 10],
      pagination: {},
      current_page: 1,
      last_page: 0,
      fields: [
        { label: "Customer", key: "name_selfpay", variant: "info" },
        { label: "Status", key: "status_code", tdClass: "myclass" },
        "pickup_time",
        "surgery_type",
        "appoinment_datetime",
        "city",
        "actions",
      ],
      fields2: ['name', 'lastname', 'phone_number', 'email', 'vehicle', 'workCities', 'actions'],
      filterNameDriver: null,
      perPageTableDriver: 15,
      currentPageTableDriver: 1,
      selectedStatus: null,
      selectedDescriptionNote: null,
      options: [
        { value: "4", title: "Cancelled" },
        { value: "1", title: "Cancellation pending" },
        { value: "3", title: "Completed" },
        { value: "7", title: "Enroute" },
        { value: "2", title: "On the way" },
        { value: "5", title: "On board" },
        { value: "6", title: "At facility" },
        { value: "0", title: "Trip pending" },
        { value: "9", title: "Driver confirmed" },
        { value: "10", title: "Driver scheduled" },
        { value: "11", title: "No show" },
        { value: "12", title: "Trip locked" },
        { value: "13", title: "*Cancellation*" },
        //{ value: "13", title: "Cancellation with fee" },
        { value: "15", title: "Update needed" },
        { value: "16", title: "Driver Waiting" },
      ],
      optionsNotes: [],
      rangeDate: null,
      searchingText: "",
      ticketToUpdateNumber: 0,
      ticketToUpdateStatus: null,
      listDrivers: [],
      listDrivers2: [],
      selectedDriverId: null,
      selectDriverName: null,
      columnOrder: 'booking_id',
      typeOrder: null,
      orderDesc: true,
      isOrdering: false,
      documentHtml: "",
      isShowSpace: false,
      driversData: [],
      typeNote: "Internal note",
      currentItem: {},
      subjectPdf: "New booking confirmation",
      actionPdf: "create pdf client",
      CREATEPDFCLIENT: "create pdf client",
      CREATEPDFDRIVER: "create pdf driver",
      MAILPDFCLIENT: "mail pdf client",
      MAILPDFDRIVER: "mail pdf driver",
      MAILPDFCOMPANY: "mail pdf company",
      NEWBOOKINGCONFIRMATION: "New booking confirmation",
      UPDATEDBOOKINGCONFIRMATION: "Updated booking confirmation",
      CANCELLEDBOOKINGCONFIRMATION: "Cancelled booking confirmation",
      currentBooking: null
    };
  },
  methods: {
    async processActionPdf(actionPdf, subject) {
      this.$bvModal.hide("modal-options-pdf");
      switch (actionPdf) {
        case this.CREATEPDFCLIENT:
          this.getPdfDetailBookingBinary(subject);
          break;
        case this.CREATEPDFDRIVER:
          this.getPdfTicketDriverBinary(subject);
          break;
        case this.MAILPDFCLIENT:
          this.sendMailPdfDetailBooking(subject);
          break;
        case this.MAILPDFDRIVER:
          this.sendMailPDFTicketDriver(subject);
          break;
        case this.MAILPDFCOMPANY:
          this.sendMailPdfDetailBookingToCompany(subject);
          break;
      }
    },
    async getPdfTicketDriverBinary(subject) {
      let res = "";

      if (
        this.validateTicketNumber() == false ||
        this.validateDriver() == false
      ) {
        return false;
      }

      this.$swal({
        title: "Please, wait...",
        didOpen: () => {
          this.$swal.showLoading();
        },
      });


      await this.$http
        .get(`/get_pdf_ticket_driver_binary/${this.ticketToUpdateNumber}/${subject}`, {
          responseType: "arraybuffer",
          headers: {
            Accept: "application/pdf",
          }
        })
        .then((response) => {
          var blob = new window.Blob([response.data], {
            type: "application/pdf",
          });
          var url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          var win = window.open();
          win.document.write(
            '<iframe src="' +
            url +
            '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>'
          );
          this.$swal.close();
        })
        .catch((error) => {
          this.$swal({
            title: getMessageError(error),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
      return res;
    },
    async getPdfDetailBookingBinary(subject) {
      let res = "";

      if (this.validateTicketNumber() == false) {
        return false;
      }

      if (this.ticketToUpdateNumber == null) {

        return false;
      }

      this.$swal({
        title: "Please, wait...",
        didOpen: () => {
          this.$swal.showLoading();
        },
      });

      await this.$http
        .get(`/get_pdf_deailst_booking_binary/${this.ticketToUpdateNumber}/${subject}`, {
          responseType: "arraybuffer",
          headers: {
            Accept: "application/pdf",
          }
        })
        .then((response) => {
          var blob = new window.Blob([response.data], {
            type: "application/pdf",
          });
          var url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          var win = window.open();
          win.document.write(
            '<iframe src="' +
            url +
            '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>'
          );
          this.$swal.close();
        })
        .catch((error) => {
          this.$swal({
            title: getMessageError(error),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
      return res;
    },
    async sendMailPdfDetailBooking(subject) {
      let res = "";

      if (this.validateTicketNumber() == false) {
        return false;
      }

      this.$swal({
        title: "Please, wait...",
        didOpen: () => {
          this.$swal.showLoading();
        },
      });

      await this.$http
        .get(`/send_mail_pdf_detailst_booking/${this.ticketToUpdateNumber}/${subject}`)
        .then((response) => {
          this.$swal({
            title: response.data.data,
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        })
        .catch((error) => {
          this.$swal({
            title: getMessageError(error),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
      return res;
    },
    async sendMailPdfDetailBookingToCompany(subject) {
      let res = "";

      if (this.validateTicketNumber() == false) {
        return false;
      }

      this.$swal({
        title: "Please, wait...",
        didOpen: () => {
          this.$swal.showLoading();
        },
      });

      await this.$http
        .get(`/send_mail_pdf_detailst_booking_to_company/${this.ticketToUpdateNumber}/${subject}`)
        .then((response) => {
          this.$swal({
            title: response.data.data,
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        })
        .catch((error) => {
          this.$swal({
            title: getMessageError(error),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
      return res;
    },
    async sendMailPDFTicketDriver(subject) {
      let res = "";

      if (
        this.validateTicketNumber() == false ||
        this.validateDriver() == false
      ) {
        return false;
      }

      this.$swal({
        title: "Please, wait...",
        didOpen: () => {
          this.$swal.showLoading();
        },
      });

      await this.$http
        .get(`/send_mail_pdf_ticket_driver/${this.ticketToUpdateNumber}/${subject}`)
        .then((response) => {
          this.$swal({
            title: response.data.data,
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        })
        .catch((error) => {
          this.$swal({
            title: getMessageError(error),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
      return res;
    },
    validateTicketNumber() {
      if (this.ticketToUpdateNumber == 0) {
        this.$swal({
          title: "Must select a register from the table",
          icon: "info",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        return false;
      }
      return true;
    },
    validateDriver() {
      if (this.selectedDriverId === null) {
        this.$swal({
          title: "This reservation has no driver",
          icon: "info",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        return false;
      }
      return true;
    },
    masterSearch(e) {
      if (e.keyCode == 13) {
        this.getClientesByFilter("");
      }
    },
    getBookings() {
      this.$http
        .post(`admin/panel/mastersearch/bookingList/` + this.searchingText)
        .then((response) => {
          this.listItems = response.data.data.data;
          this.totalItems = response.data.data.total;
          this.current_page = response.data.data.current_page;
          this.last_page = response.data.data.last_page;
          this.pagination = response.data.data;
        })
        .catch((res) => {
          console.log(res.data);
        });
    },
    // Convert time to a format of hours, minutes, seconds, and milliseconds
    getTime(id, sec, min, hour, dispSec, dispMin, dispHour) {

      this.timerInterval[id] = window.setInterval(function () {
        // console.log('this.timerInterval[id]: ',this.timerInterval[id])

        sec++;
        4;
        this.totalSeconds = sec;
        if (sec / 60 == 1) {
          min++;
          sec = 0;
          if (min / 60 == 1) {
            hour++;
            min = 0;
          }
        }
        if (sec < 10) {
          if (sec.toString().length == 1) {
            dispSec = "0" + sec.toString();
          } else {
            dispSec = sec.toString();
          }
        } else {
          dispSec = sec.toString();
        }
        if (min < 10) {
          dispMin = "0" + min.toString();
        } else {
          dispMin = min.toString();
        }
        if (hour < 10) {
          dispHour = "0" + hour.toString();
        } else {
          dispHour = hour.toString();
        }
        var start = document.getElementsByClassName("start" + id);
        for (let i = 0; i < start.length; i++) {
          start[i].style.display = "none";
        }

        var stop = document.getElementsByClassName("stop" + id);
        for (let i = 0; i < stop.length; i++) {
          stop[i].style.display = "block";
        }

        var span = document.getElementById("span" + id);
        if (span) {
          span.classList.add("green_timer");
        }

        let disp = document.getElementById("display" + id);
        if (disp) {
          disp.innerHTML =
            dispHour + ":" + dispMin + ":" + dispSec;
        }

      }, 1000);
    },
    async startTimer(item, showMessage) {
      //variables
      let waitingTime = item.passenger_waiting_time_for_driver;
      let array_wait_time = "";
      let sec = 0;
      let min = 0;
      let hour = 0;
      let regExp = /[a-zA-Z]/g;

      //set values
      let dateWaiting = moment(item.passenger_waiting_time_for_driver_datetime);
      let currentDate = moment();
      let accumulatedWaitingTime = waitingTime;
      let totalTimeInSeconds = 0;

      //if waiting datetime is valid datetime
      if (dateWaiting.isValid()) {
        totalTimeInSeconds = currentDate.diff(dateWaiting, "seconds");
        if (
          accumulatedWaitingTime == "null" ||
          accumulatedWaitingTime == null
        ) {
          accumulatedWaitingTime = "00:00:01";
        } else {
          accumulatedWaitingTime = addTimes(
            secsToTime(totalTimeInSeconds),
            accumulatedWaitingTime
          );
        }
      }

      //Get the waiting time for example 00:10:12
      if (accumulatedWaitingTime) {
        array_wait_time = accumulatedWaitingTime.split(":");
      }
      if (array_wait_time.length == 3) {
        sec = array_wait_time[2].replace(/^0+/, "");
        min = array_wait_time[1].replace(/^0+/, "");
        hour = array_wait_time[0].replace(/^0+/, "");
      }
      //If has characteres
      if (
        regExp.test(array_wait_time[0]) ||
        regExp.test(array_wait_time[1]) ||
        regExp.test(array_wait_time[2])
      ) {
        hour = 0;
        min = 0;
        sec = 0;
      } else {
        //if has not characteres
        if (accumulatedWaitingTime) {
          hour = Number(array_wait_time[0]);
          min = Number(array_wait_time[1]);
          sec = Number(array_wait_time[2]);
        }
      }

      //load time 
      this.getTime(item.booking_id, sec, min, hour, 0, 0, 0);
      currentDate = currentDate.format("YYYY-MM-DD HH:mm:ss");

      await this.saveTime(
        item.booking_id,
        accumulatedWaitingTime,
        false,
        currentDate
      );

      //reset values item
      item.passenger_waiting_time_for_driver_datetime = currentDate;
      item.passenger_waiting_time_for_driver = accumulatedWaitingTime;

      //Show message
      if (showMessage) {
        this.$swal({
          title: "Timer Start",
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }
    },
    //clear times
    clearIntervalTimes(item) {
      let id = item.booking_id;
      if (this.timerInterval.length != 0) {
        clearInterval(this.timerInterval[id]);
      } else {
        var storedColors = JSON.parse(localStorage.getItem("timerIds"));
        if (storedColors) {
          clearInterval(storedColors[id]);
        }

      }
    },
    stopTimer(item) {
      let id = item.booking_id;

      if (this.timerInterval.length != 0) {
        clearInterval(this.timerInterval[id]);
      } else {
        var storedColors = JSON.parse(localStorage.getItem("timerIds"));
        clearInterval(storedColors[id]);
      }

      var span = document.getElementById("span" + id);
      if (span) {
        span.classList.remove("green_timer");
      }

      var start = document.getElementsByClassName("start" + id);
      for (let i = 0; i < start.length; i++) {
        start[i].style.display = "block";
      }

      var stop = document.getElementsByClassName("stop" + id);
      for (let i = 0; i < stop.length; i++) {
        stop[i].style.display = "none";
      }

      //variables
      let waitingDatetime = moment(
        item.passenger_waiting_time_for_driver_datetime
      );
      let dateCurrent = moment();
      let totalTimeInSeconds = 0;
      let accumulatedWaitingTime = "";

      //calcualate accumulated waiting time
      if (waitingDatetime.isValid()) {
        totalTimeInSeconds = dateCurrent.diff(waitingDatetime, "seconds");
        accumulatedWaitingTime = item.passenger_waiting_time_for_driver;
        if (accumulatedWaitingTime == null) {
          accumulatedWaitingTime = document.getElementById(
            "display" + item.booking_id
          ).innerHTML;
          item.passenger_waiting_time_for_driver = accumulatedWaitingTime;
        } else {
          accumulatedWaitingTime = addTimes(
            secsToTime(totalTimeInSeconds),
            accumulatedWaitingTime
          );
        }
        document.getElementById("display" + item.booking_id).innerHTML =
          accumulatedWaitingTime;
      } else {
        accumulatedWaitingTime = document.getElementById(
          "display" + item.booking_id
        ).innerHTML;
      }
      waitingDatetime = null;

      //save the time
      this.saveTime(item.booking_id, accumulatedWaitingTime, true, waitingDatetime);

      //reset values item
      item.passenger_waiting_time_for_driver_datetime = waitingDatetime;
      item.passenger_waiting_time_for_driver = accumulatedWaitingTime;
    },
    saveTime(id, accumulatedWaitingTime, showMessage, waitingDatetime) {
      let formData = new FormData();

      formData.append("w_time", accumulatedWaitingTime);
      formData.append(
        "passenger_waiting_time_for_driver_datetime",
        waitingDatetime
      );

      if (showMessage) {
        this.$swal({
          title: "Please, wait...",
          didOpen: () => {
            this.$swal.showLoading();
          },
        });
      }

      this.$http
        .post(`/admin/panel/booking/savetime/${id}`, formData)
        .then((response) => {
          if (showMessage) {
            this.$swal({
              title: "Timer Stoped",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            }).then((result) => { });
          }
        })
        .catch((error) => {
          this.$swal({
            title: "error",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
    convertDate(data) {
      return convertToMMDDYYYY(data);
    },
    convertTime(data) {
      return convertToHHMM(data);
    },
    convertTime2(data) {
      return convertToHHMM2(data);
    },
    getItems(url) {
      this.getClientesByFilter(url);
    },
    getClientes(url) {
      this.$swal({
        title: "Please, wait...",
        didOpen: () => {
          this.$swal.showLoading();
        },
      });

      if (url.length === 0) {
        url = "admin/panel/booking/all_list";
      }

      this.$http
        .get(url)
        .then((response) => {
          this.listItems = response.data.data.data;
          this.totalItems = response.data.data.total;
          this.current_page = response.data.data.current_page;
          this.last_page = response.data.data.last_page;
          this.pagination = response.data.data;
          this.$swal.close();
        })
        .catch((res) => {
          let message = getMessageError(res);
          this.$swal({
            title: message,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
    loadPassengerWaitingTimeForDriver() {
      this.listItems.forEach((element) => {
        let dateWaiting = moment(
          element.passenger_waiting_time_for_driver_datetime
        );
        if (dateWaiting.isValid()) {
          this.clearIntervalTimes(element);
          this.startTimer(element, false);
        }
      });
    },
    async getClientesByFilter(url) {
      this.setValuesFilter();
      this.$swal({
        title: "Please, wait loading data...",
        didOpen: () => {
          this.$swal.showLoading();
        },
      });

      let data = this.getValuesParameters();

      if (url.length === 0) {
        url = `admin/panel/booking/get_booking_byFilter`;
      }

      await this.$http
        .post(url, data)
        .then((response) => {
          this.listItems = response.data.data.data;
          this.totalItems = response.data.data.total;
          this.current_page = response.data.data.current_page;
          this.last_page = response.data.data.last_page;
          this.pagination = response.data.data;
          this.$swal.close();
          this.loadPassengerWaitingTimeForDriver();
        })
        .catch((res) => {
          this.$swal({
            title: getMessageError(res),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
    getClientesToCheckDbChange(url) {
      let data = this.getValuesParameters();

      if (url.length === 0) {
        url = `admin/panel/booking/get_booking_byFilter`;
      }

      this.$http
        .post(url, data)
        .then((response) => {
          this.listItems = response.data.data.data;
          this.totalItems = response.data.data.total;
          this.current_page = response.data.data.current_page;
          this.last_page = response.data.data.last_page;
          this.pagination = response.data.data;
          this.$swal.close();
        })
        .catch((res) => { });
    },
    getValuesParameters() {
      let data = {};
      let dateStart = "";
      let dateEnd = "";
      let text = "";

      //Status variable filter
      let status = {};
      if (this.selectedStatus !== null) {
        status = this.selectedStatus.map((item) => {
          let value = item.value;
          return value;
        });
      }

      //Dates variables filter
      if (this.rangeDate !== null) {
        dateStart = this.rangeDate.split("to")[0];
        dateEnd = this.rangeDate.split("to")[1];
      }

      data = {
        status: status,
        dateStart: dateStart,
        dateEnd: dateEnd,
        text: this.searchingText,
        columnOrder: this.columnOrder,
        orderDesc: this.orderDesc,
      };

      return data;
    },
    deleteReservation(id) {
      this.$swal({
        title: "Do you want delete this record?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        buttonsStyling: true,
      }).then((result) => {
        if (result.value === true) {
          this.$swal({
            title: "Please, wait...",
            didOpen: () => {
              this.$swal.showLoading();
            },
          });
          this.$http
            .post(`/admin/panel/booking/${id}/delete`)
            .then((res) => {
              this.$swal({
                title: res.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              this.listItems = this.listItems.filter(x => x.booking_id != id);
            })
            .catch((error) => {
              this.$swal({
                title: getMessageError(error),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    setValuesForm(item) {
      this.currentId = item.booking_id;
      this.status = item.status;
      this.messageText = "";
      this.currentAlternativeNumberDriver =
        item.alternative_number_driver == null
          ? ""
          : item.alternative_number_driver;
      this.currentAlternativeNumberPassenger =
        item.alternative_number_passenger == null
          ? ""
          : item.alternative_number_passenger;
      this.isDefaultMessage = false;
      this.textDefaultMessage = `Confirmation #: ${item.booking_id}\n${this.getSelfPayName(item)}\Pickup time:  ${this.convertTime2(item.booking_pickup_time)}\nFrom: ${this.getFromLocationBooking(item)}\nDest: ${this.getToLocationBooking(item)}`
    },
    getFromLocationBooking(booking) {
      let res = "";

      try {
        res = JSON.parse(booking.from).from;
      } catch (error) {
        console.log(error)
      }
      return res;
    },
    getToLocationBooking(booking) {
      let res = "";

      try {
        res = JSON.parse(booking.to).to;
      } catch (error) {
        console.log(error)
      }
      return res;
    },
    getSelfPayName(booking) {
      let res = "";

      try {
        res = `${booking.selfpay_name} ${booking.selfpay_lastname}`
      } catch (error) {
        console.log(error)
      }
      return res;
    },
    setDefaulText() {

      if (this.isDefaultMessage == true) {
        this.messageText = this.textDefaultMessage;
      } else {
        this.messageText = "";
      }

    },
    changeColor() {
      this.isNoteText = true;
    },
    changeColorMessage() {
      this.isMessageText = true;
    },
    saveNote(id, typeNote, typelog) {
      if (this.messageText === "") {
        this.isMessageText = false;
      } else {
        this.$bvModal.hide("bv-modal-example");
        this.$bvModal.hide("bv-modal-add-note");

        this.$swal({
          title: "Please, wait...",
          didOpen: () => {
            this.$swal.showLoading();
          },
        });
        let formData = new FormData();
        formData.append("text", this.messageText);
        formData.append("status", this.status);
        formData.append("messageSendTo", this.messageSendTo);
        formData.append("type_log", typelog);
        formData.append("type_note", typeNote);
        formData.append("alternative_number_driver", this.currentAlternativeNumberDriver);
        formData.append("alternative_number_passenger", this.currentAlternativeNumberPassenger);
        formData.append("datetime_log", moment().format("YYYY-MM-DD hh:mm A"));


        this.$http
          .post(`/admin/panel/booking/${id}/saveBookingNote`, formData)
          .then((res) => {
            let message = "";
            if (typelog === 'sms') {
              message = 'Sms sent';
            } else {
              message = res.data.message;
            }

            this.$swal({
              title: message,
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            }).then((result) => {
              if (result.isConfirmed) {
                this.getClientesByFilter("");
              }
            });
          })
          .catch((error) => {
            this.$swal({
              title: getMessageError(error),
              icon: "error",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          });
      }
    },
    cancelBooking(id) {
      if (this.noteText === "") {
        this.isNoteText = false;
      } else {
        this.$bvModal.hide("modal-center");

        this.$swal({
          title: "Please, wait...",
          didOpen: () => {
            this.$swal.showLoading();
          },
        });
        let formData = new FormData();
        formData.append("text", this.noteText);
        this.$http
          .post(`/admin/panel/booking/${id}/cancelBooking`, formData)
          .then((res) => {
            this.$swal({
              title: res.data.message,
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            }).then((result) => {
              if (result.isConfirmed) {
                this.getClientesByFilter("");
              }
            });
          })
          .catch((error) => {
            this.$swal({
              title: getMessageError(error),
              icon: "error",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          });
      }
    },
    sendConfirmation(id) {
      this.$swal({
        title: "Please, wait...",
        didOpen: () => {
          this.$swal.showLoading();
        },
      });
      this.$http
        .post(`/admin/panel/booking/${id}/cancel`)
        .then((response) => {
          this.$swal({
            title: response.data.data,
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          }).then((result) => {
            if (result.isConfirmed) {
              this.getClientesByFilter("");
            }
          });
        })
        .catch((error) => {
          this.$swal({
            title: "Your reservation has already cancelled",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
    getDriverName(item) {
      let res = "";
      if (item.driver) {
        res = `${item.driver_name} ${item.driver_lastname}`;
      }
      return res;
    },
    getDriverId(item) {
      let res = "";
      if (item.driver) {
        res = item.driver.driver_id;
      } else {
        res = null;
      }
      return res;
    },
    getAddress(item) {
      let res = "";

      if (item) {
        res = JSON.parse(item);
      }
      return res;
    },
    getDriverPhone(item) {
      let res = "";
      if (item.driver) {
        res = item.driver.phone_number;
        res = res == "null" ? "" : res;
      }
      return res;
    },
    getDriverVehicle(item) {
      let res = "";
      try {
        res = item == null || item == "null" ? "" : item.toUpperCase();
      } catch (error) {
        console.log(error);
      }
      return res;
    },
    getSelfPayName(item) {
      let res = "";
      if (item.self_pay) {
        res = item.selfpay_name + " " + item.selfpay_lastname;
      }
      return res;
    },
    getSelfPhone(item) {
      let res = "";
      if (item.self_pay) {
        res = item.self_pay.phone_number;
        res = res == "null" ? "" : res;
      }
      return res;
    },
    getTypeClient(item) {
      let res = "";
      try {
        if (item.payment_type == null) {
          res = "Selfpay"
        }
        if (item.payment_type == 'il') {
          res = "Contract Account"
        }
        if (item.payment_type == 'cc') {
          res = "Corporate Account"
        }

        return res;

      } catch (error) {

      }

      return res;
    },
    getCompanyName(item) {
      let res = "";
      try {
        if (item.payment_type == null) {
          res = "Selfpay";
        } else {
          res = item.company_legal_name;
        }

        return res;
      } catch (error) {

      }

      return res;
    },
    getTicketData(item) {
      if (item.booking_id === this.ticketToUpdateNumber) {
        this.ticketToUpdateNumber = null;
        this.ticketToUpdateStatus = null;
        this.selectDriverName = null;
        this.selectedDriverId = null;
        this.currentItem = null;
        return false;
      }
      if (item.status == 4) {
        this.subjectPdf = this.CANCELLEDBOOKINGCONFIRMATION;
      } else {
        this.subjectPdf = this.UPDATEDBOOKINGCONFIRMATION;
      }
      this.currentItem = item;
      this.ticketToUpdateNumber = item.booking_id;
      this.ticketToUpdateStatus = this.options.filter(
        (x) => x.value == item.status
      );
      let driverName = this.getDriverName(item);

      this.selectDriverName = driverName;
      this.selectedDriverId = item.driver_id;
    },
    updateStatusBooking(pTicketNumber, pTicket, bookingData) {

      let ticketNumber = pTicketNumber;
      let ticketTitleStatus = null;
      let tickedValueStatus = null;

      if (pTicket) {
        ticketTitleStatus = pTicket.title;
        tickedValueStatus = pTicket.value;
      }

      if (ticketNumber == 0 || ticketNumber == null) {
        this.$swal({
          title: "Must select a register from the table",
          icon: "info",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        return false;
      }
      if (tickedValueStatus === undefined) {
        return false;
      }

      if (this.ticketToUpdateStatus.value == 4) {
        bookingData.booking_is_automatic_price = "0";
        bookingData.booking_driver_price = 0;
        bookingData.booking_additional_charges_driver = 0;
        bookingData.booking_price = 0;
      }

      if (bookingData.booking_additional_charges_driver == null) {
        bookingData.booking_additional_charges_driver = 0;
      }

      if (bookingData.booking_driver_price == null) {
        bookingData.booking_driver_price = 0;
      }

      this.$swal({
        title: "Are you sure you want to change the status? If yes, please confirm that you have audited the ticket and that all information, including pricing, is correct?",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        buttonsStyling: true,
      }).then((result) => {
        if (result.value === true) {


          let formData = new FormData();
          formData.append("status", tickedValueStatus);

          formData.append("is_automatic_price", bookingData.booking_is_automatic_price);
          formData.append("driver_price", bookingData.booking_driver_price);
          formData.append("additional_charges_driver", bookingData.booking_additional_charges_driver);
          formData.append("price", bookingData.booking_price);

          this.$swal({
            title: "Please, wait updating...",
            didOpen: () => {
              this.$swal.showLoading();
            },
          });
          this.$http
            .post(
              `/admin/panel/booking/${ticketNumber}/modify_booking_status`,
              formData
            )
            .then((response) => {
              //Update the status code item
              let item = this.listItems.find(
                (x) => x.booking_id == this.ticketToUpdateNumber
              );
              item.status = tickedValueStatus;
              item.statuscodes_code = tickedValueStatus;
              item.statuscodes_status = ticketTitleStatus;
              this.$swal.close();
            })
            .catch((error) => {
              this.$swal({
                title: getMessageError(error),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });




        }
      });
    },
    updateDriverBooking() {
      if (this.validateData() == false) {
        return false;
      }
      this.$swal({
        title: "Please, wait...",
        didOpen: () => {
          this.$swal.showLoading();
        },
      });
      this.$http
        .post(
          `admin/panel/booking/${this.ticketToUpdateNumber}/edit_driver/${this.selectedDriverId}`
        )
        .then((response) => {
          this.$swal({
            title: response.data.message,
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });

          let driverID = this.selectedDriverId;
          let driver = this.driversData.find((x) => x.driver_id == driverID);

          let bookingItem = this.listItems.find(
            (x) => x.booking_id == this.ticketToUpdateNumber
          );

          bookingItem.driver_name = driver.name;
          bookingItem.driver_lastname = driver.lastname;
          bookingItem.driver_phone_number = driver.phone_number;

          if (driver.vehicle) {
            bookingItem.driver_vehicle_type = this.getDriverVehicle(driver.vehicle.vehicle_type);
          }

        })
        .catch((error) => {

          let messageError = getMessageError(error);

          if (messageError == 'Driver price must have a value') {
            this.$swal.close();
            this.$bvModal.show("modal-error-driver-price");
          } else {
            this.$swal({
              title: messageError,
              icon: "error",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          }

        });
    },
    openEditBookingFromAssignDriver() {
      const routeData = this.$router.resolve({ name: 'details-reservation', query: { data: this.ticketToUpdateNumber } });
      window.open(routeData.href, '_blank');
    },
    validateData() {
      let res = false;
      if (this.ticketToUpdateNumber == 0) {
        this.$swal({
          title: "Must select a register from the table",
          icon: "info",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        return false;
      }
      if (this.selectedDriverId == null) {
        this.$swal({
          title: "Must select a driver",
          icon: "info",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        return false;
      }
    },
    openUpdateBooking(id) {
      this.$router.push({
        name: "details-reservation",
        params: { id: id },
      });
    },
    getDrivers() {
      let status = 'Approved';
      this.$http
        .get(`admin/panel/driver/list?availability=1&filterBy=VW&status=${status}`)
        .then((response) => {
          this.loadMenuDrivers(response.data.data);
          this.driversData = response.data.data;
        })
        .catch((res) => console.log(res.data));
    },
    loadMenuDrivers(items) {
      items.forEach((element) => {
        let workCities = "";
        for (let i = 0; i < element.work_cities.length; i++) {
          workCities =
            workCities +
            ` *CITY:${element.work_cities[i].city}, ${element.work_cities[i].state}`;
        }

        let vehicle =
          element.vehicle.vehicle_type == "null" ||
            element.vehicle.vehicle_type == null
            ? ""
            : `- *VEHICLE:${element.vehicle.vehicle_type.toUpperCase()}`;

        let vehicle2 =
          element.vehicle.vehicle_type == "null" ||
            element.vehicle.vehicle_type == null
            ? ""
            : `${element.vehicle.vehicle_type.toUpperCase()}  `;

        let data = {
          value: element.driver_id,
          title: `NAME:${element.name} ${element.lastname} - *EMAIL:${element.email} ${vehicle} ${workCities}`,
        };
        this.listDrivers.push(data);
        let info = {
          id: element.id,
          name: element.name,
          lastname: element.lastname,
          email: element.email,
          vehicle: vehicle2,
          workCities: workCities,
          phone_number: element.phone_number
        }
        this.listDrivers2.push(info);
      });
    },
    loadMenuOptionNote() {
      this.optionsNotes = getMenuNotes();
    },
    getItemsSort(column) {
      if (this.isOrdering == false) {
        this.orderDesc = this.orderDesc == null ? true : !this.orderDesc;
        this.columnOrder = column;
        this.getClientesByFilter("");
      }
    },
    selectedRow() {
      var index,
        table = document.getElementById("table");

      if (table == null || table == undefined) {
        return false;
      }

      for (var i = 1; i < table.rows.length; i++) {
        table.rows[i].classList.remove("selected");
        table.rows[i].onclick = function () {
          // remove the background from the previous selected row
          if (typeof index !== "undefined") {
            table.rows[index].classList.toggle("selected");
          }
          // get the selected row index
          index = this.rowIndex;
          // add class selected to the row
          this.classList.toggle("selected");
        };
      }
    },
    selectDriver(item, bvModal) {
      bvModal.hide('modal-search-driver');

      let driver = this.driversData.find((x) => x.id == item.id);
      this.selectedDriverId = driver.driver_id;
      this.selectDriverName = `${driver.name} ${driver.lastname}`;
      this.filterNameDriver = '';
      this.updateDriverBooking();
    },
    async loadData() {
      this.loadMenuOptionNote();
      if (this.$route.query.search) {
        this.searchingText = this.$route.query.search;
      }
      await this.getClientesByFilter("");
      this.getDrivers();
    },
    addDescriptionNote() {
      this.messageText += this.selectedDescriptionNote.value + "\n";
      this.selectedDescriptionNote = null;
    },
    setValuesFilter() {
      sessionStorage.setItem("selectedStatus", JSON.stringify(this.selectedStatus));
      sessionStorage.setItem("rangeDate", JSON.stringify(this.rangeDate));
      sessionStorage.setItem("searchingText", JSON.stringify(this.searchingText));
      sessionStorage.setItem("ticketToUpdateNumber", JSON.stringify(this.ticketToUpdateNumber));
      sessionStorage.setItem("ticketToUpdateStatus", JSON.stringify(this.ticketToUpdateStatus));
      sessionStorage.setItem("selectDriverName", JSON.stringify(this.selectDriverName));
    },
    getCurrentValuesFilter() {
      this.selectedStatus = JSON.parse(sessionStorage.getItem("selectedStatus"));
      this.rangeDate = JSON.parse(sessionStorage.getItem("rangeDate"));
      this.searchingText = JSON.parse(sessionStorage.getItem("searchingText"));
      this.ticketToUpdateNumber = JSON.parse(sessionStorage.getItem("ticketToUpdateNumber"));
      this.ticketToUpdateStatus = JSON.parse(sessionStorage.getItem("ticketToUpdateStatus"));
      this.selectDriverName = JSON.parse(sessionStorage.getItem("selectDriverName"));
    },
    getServiceType(item) {
      try {
        if (item == 'sedan') {
          return 'Sedan';
        }
        if (item == 'wheelchairvan') {
          return 'Wheelchair Van';
        }
        if (item == 'gurneygan') {
          return 'Ambulance';
        }
        return item;
      } catch (error) {

      }

      return "";
    },
    getCollabUser(data) {
      let res = data;
      try {
        let containsNameAndEmail = data.includes("||");
        if (containsNameAndEmail == true) {
          res = data.split("||")[0];
        }

      } catch (error) {

      }
      return res;
    },
    async exportBooking() {
      let res = "";
      this.$swal({
        title: "Please, wait...",
        didOpen: () => {
          this.$swal.showLoading();
        },
      });
      let data = this.getValuesParameters();


      await this.$http
        .post(`/export_bookings`, data, {
          responseType: "arraybuffer",
          headers: {
            Accept: "application/zip",
          },

        })
        .then((response) => {

          var blob = new window.Blob([response.data], {
            type: "application/zip",
          });
          this.getClientesByFilter("");
          if (blob.size > 0) {
            var url = URL.createObjectURL(blob);
            //const link = document.createElement("a");
            var win = window.open();
            win.document.write(
              '<iframe src="' +
              url +
              '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>'
            );
          }

          this.$swal.close();
        })
        .catch((error) => {
          this.$swal({
            title: getMessageError(error),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
      return res;
    },
    setCurrentBooking(item) {
      this.currentBooking = item;
    },
    getStatusBooking(item) {
      let res = "";

      try {
        res = item.statuscodes_status;
      } catch (error) {

      }
      return res;
    },
    setFrom(item) {
    let res = null;
    if (item.from != null) {
      res = JSON.parse(item.from);
    }
    return res;
  }
  },

  mounted() {
    this.getCurrentValuesFilter();
    this.loadData();
    //SocketioService.setupSocketConnection();

  },
  beforeDestroy() {
    if (this.timerInterval.length != 0) {
      localStorage.setItem("timerIds", JSON.stringify(this.timerInterval)); //store colors
    }
  },
  updated() {
    selectedRow();
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.urlPagina {
  text-decoration: none;
  color: #7367f0;
}

.urlPagina:hover {
  background: linear-gradient(118deg,
      #7367f0,
      rgba(115, 103, 240, 0.7)) !important;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
}

.list-group-item:hover {
  background: linear-gradient(118deg,
      #7367f0,
      rgba(115, 103, 240, 0.7)) !important;
  color: #fff !important;
  cursor: pointer;
}

.urlPagina::before {
  background-color: currentColor !important;
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.box {
  box-shadow: 0px 14px 20px 0px rgba(143, 143, 143, 0.2) !important;
}

.my-class {
  background: #7367f0;
}

.green_timer {
  background: green;
  color: white;
  padding: 3px;
  border-radius: 8px;
}

.current {
  background: #7367f0;
  color: white;
}

.table-responsive {
  overflow-x: clip !important;
}

.headerth {
  cursor: pointer;
}

.highlighted {
  background: red;
}

tr {
  cursor: pointer;
  padding: 0;
}

th {
  background: #4b4b4b !important;
  color: white !important;
  text-transform: uppercase;
}

td {
  padding: 0;
}

.table td,
.table th {
  padding: 3px;
  font-size: x-small;
  min-width: 40px;
  border: solid 1px;
  border-color: #303033;
  background: #fdfdfd;
  color: #303033;
}

.show_space {
  padding-bottom: 250px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
